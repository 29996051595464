import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const PocetnaHero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.onresize = () => setWindowWidth(window.innerWidth);
  }, []);

  return (
    <div className="bg-center bg-cover container-outer relative flex items-center min-h-[calc(100vh-71px)] max-h-[1440px] light-gradient">
      <div className="container flex flex-col gap-10 items-center relative z-10">
        <div className="text-light-100 flex flex-col gap-8 items-center md:items-start">
          <div className="text-center flex flex-col items-center md:items-start">
            <h1 className="font-bold text-4xl sm:text-5xl mb-6 max-w-[20ch] tracking-wide text-light-300">
              Iznajmljivanje Kampera i Prikolica Niš
            </h1>
            <p className="text-xl md:text-2xl max-w-[30ch]s md:max-w-[35ch]s mx-auto">
              Udobna i Pouzdana Vozila za Vašu Sledeću Avanturu
            </p>
          </div>

          {windowWidth > 700 ? (
            <AnchorLink
              href="#kontakt"
              className="px-8 py-3 mx-auto bg-light-100 text-secondary-100 font-bold rounded-lg transition-colors duration-300 hover:bg-light-200"
            >
              Kontakt
            </AnchorLink>
          ) : (
            <a
              href="tel:0643629189"
              className="px-8 py-3 mx-auto bg-light-100 text-secondary-100 font-bold rounded-lg transition-colors duration-300 hover:bg-light-200"
            >
              Kontakt
            </a>
          )}
        </div>
        {windowWidth > 700 ? (
          <img
            src={require("../Pictures/HeroImage.png")}
            alt="HeroImage"
            className="min-w-[40rem]"
          />
        ) : (
          <img
            src={require("../Pictures/HeroImageSmall.png")}
            alt="HeroImage"
          />
        )}
      </div>
    </div>
  );
};

export default PocetnaHero;
