const data = [
  {
    name: "Kamper",
    slika: "CamperDefault.png",
    heroSlika: "CamperHero.png",
    ukratko: ["3 Osobe", "B Kategorija", "Tenda"],
    tekst:
      "Kamper Iveco, koji ima kapacitet za tri osobe i vozi se sa B kategorijom. Tehnički u potpunosti ispravan i spreman za duga putovanja. Poseduje toalet(sa kasetom koja se prazni od spolja), sa umivaonikom i tušem. Kuhinja sa plinskim rešoom, frižiderom(gas, 12v, 220v), sudoperom i kuhinjskim priborom. Plinsko grejanje i klima. Nosač bicikli i veliki tovarni prostor za opremu i lične stvari sa još mnogo polica u samom kamperu. Tenda koja je u sklopu vozila. Može biti priključen na napajanje 220v ili potpuno nezavistan(poseduje solarni panel i GEL akumulator).",
    link: "/kamper",
    ostaleSlike: [
      {
        link: "Kamper1.jpg",
        alt: "Kamper1",
      },
      {
        link: "Kamper8.jpg",
        alt: "Kamper3",
      },
      {
        link: "Kamper4.jpg",
        alt: "Kamper4",
      },
      {
        link: "Kamper5.jpg",
        alt: "Kamper8",
      },
      {
        link: "KamperUnutra1.jpg",
        alt: "KamperUnutra1",
      },
      {
        link: "KamperUnutra4.jpg",
        alt: "KamperUnutra4",
      },
      {
        link: "KamperUnutra6.jpg",
        alt: "KamperUnutra6",
      },

      {
        link: "Kamper7.jpg",
        alt: "KamperUnutra1",
      },
      {
        link: "Kamper11.jpg",
        alt: "KamperUnutra4",
      },
      {
        link: "KampPrikolicaUnutra4.jpg",
        alt: "KamperUnutra6",
      },
    ],
  },
  {
    name: "Kamp Prikolica",
    slika: "CampPrikolicaDefault.png",
    heroSlika: "CampPrikolicaHero.png",
    ukratko: ["Klima", "Solarni Panel", "Restauirana"],
    tekst:
      "Iznajmljivanje prikolice i džipa sa slike. Prikolica je u perfektnom stanju, kompletno restaurirana sa novim stvarima. Poseduje plinsku peć za grejanje, klimu, plinski rešo, rernu, frižider, TV, ozvučenje, toalet, 5 mesta za spavanje. .. Idealna za pecaroše, ljubitelje prirode, avanturiste. .. Može biti samostalna (poseduje akumulator i solarni panel) i priključena na struju.",
    link: "/kamp-prikolica",
    ostaleSlike: [
      {
        link: "KampPrikolicaUnutra11.jpg",
        alt: "Kamper1",
      },
      {
        link: "KampPrikolica18.jpg",
        alt: "Kamper3",
      },
      {
        link: "KampPrikolica1.jpg",
        alt: "Kamper4",
      },
      {
        link: "DzipKampPrikolica3.jpg",
        alt: "Kamper8",
      },
      {
        link: "KampPrikolicaUnutra4.jpg",
        alt: "Kamper8",
      },
      {
        link: "KampPrikolicaUnutra5.jpg",
        alt: "Kamper8",
      },
      {
        link: "KampPrikolicaUnutra8.jpg",
        alt: "Kamper8",
      },
      {
        link: "KampPrikolicaUnutra2.jpg",
        alt: "Kamper8",
      },
      {
        link: "KampPrikolicaUnutra3.jpg",
        alt: "Kamper8",
      },
      {
        link: "KampPrikolicaUnutra14.jpg",
        alt: "Kamper8",
      },
    ],
  },
  {
    name: "Prikolica",
    slika: "PrikolicaDefault.png",
    heroSlika: "PrikolicaHero.png",
    ukratko: ["5.2m x 2.15m", "2700kg", "Novo"],
    tekst:
      "Šlep prikolica. Dimenzije 5.20m x 2.15m, korisna nosivost 2700kg ukupne nosivosti 3500kg. Moguć transport svih vrsta automobila, džipova, traktora i kombi vozila. Prikolica je nova i potpuno spremna za transport.",
    link: "/prikolica",
    ostaleSlike: [
      {
        link: "Prikolica7.jpg",
        alt: "Kamper1",
      },
      {
        link: "Prikolica11.jpg",
        alt: "Kamper3",
      },
      {
        link: "Prikolica12.jpg",
        alt: "Kamper4",
      },
      {
        link: "Prikolica16.jpg",
        alt: "Kamper8",
      },

      {
        link: "Prikolica1.jpg",
        alt: "Kamper3",
      },
      {
        link: "Prikolica8.jpg",
        alt: "Kamper4",
      },
      {
        link: "Prikolica6.jpg",
        alt: "Kamper8",
      },

      {
        link: "Prikolica14.jpg",
        alt: "Kamper3",
      },
      {
        link: "Prikolica17.jpg",
        alt: "Kamper4",
      },
      {
        link: "Prikolica19.jpg",
        alt: "Kamper8",
      },
    ],
  },
];

export default data;
