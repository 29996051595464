import React from "react";
import Hero from "../../Hero";
import StranicaElement from "./StranicaElement";
import "./Stranica.css";

const Stranica = ({ data }) => {
  return (
    <>
      <Hero data={data} />
      <div className="container-outer py-8 sm:py-10 bg-light-100">
        <div className="container">
          <div className="slike-grid">
            {data.ostaleSlike.map((slika) => (
              <StranicaElement key={Math.random()} data={slika} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stranica;
