import React from "react";
import data from "../../Data";
import Rent from "./Rent";
import "./StaPruzamo.css";

const StaPruzamo = () => {
  return (
    <div
      className="container-outer relative pb-24 pt-12 md:pt-16"
      id="sta-pruzamo"
    >
      <h2 className=" text-center font-bold text-4xl md:text-5xl mb-16 md:mb-20 px-6">
        Šta Pružamo
      </h2>
      <div className="container flex flex-wrap justify-center gap-[10%] gap-y-12 md:gap-x-[6.666%] staPruzamoWrapper">
        {data.map((el) => (
          <Rent key={Math.random()} data={el} />
        ))}
      </div>
    </div>
  );
};

export default StaPruzamo;
