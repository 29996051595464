import React from "react";
import { Link } from "react-router-dom";

const Rent = ({ data }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="grid md:flex items-center gap-8 bg-secondary-200 rounded-3xl md:h-96 overflow-hidden py-12">
      <div className="flex flex-col gap-4 justify-start px-6">
        <h3 className="text-4xl lg:text-5xl font-bold">{data.name}</h3>
        <p className="line-clamp-5 md:line-clamp-3">{data.tekst}</p>
        <div className="w-max h-max mt-6 py-3 rounded-lg bg-light-100 hover:bg-light-200 transition-colors duration-300 text-secondary-100 font-bold">
          <Link
            onClick={scrollToTop}
            to={data.link}
            className="w-max px-8 py-3"
          >
            Pročitaj Više
          </Link>
        </div>
      </div>
      <div className="overflow-hidden shrink-0">
        <img
          src={require(`../../Pictures/${data.slika}`)}
          alt="slika"
          className="aspect-video object-cover transition-transform duration-500"
        />
      </div>
    </div>
  );
};

export default Rent;
