import React from "react";

const StranicaElement = ({ data }) => {
  return (
    <div className="overflow-hidden rounded-3xl">
      <img
        src={require(`../../Pictures/${data.link}`)}
        alt={data.alt}
        className="object-cover w-full h-full object-center"
      />
    </div>
  );
};

export default StranicaElement;
