import React from "react";
import { Email, Instagram, TikTok } from "./Icons/Icons";

const Footer = () => {
  return (
    <div
      className="container-outer pt-12 md:pt-16 pb-4 relative overflow-hidden"
      id="kontakt"
    >
      <div className="container flex flex-col items-center gap-12 lg:gap-24">
        <div className="flex flex-col items-center text-center gap-4">
          <h3 className="font-bold text-4xl md:text-5xl">Kontakt</h3>
          <div className="flex items-center justify-center flex-wrap gap-4">
            <a href="tel:0643629189">064-3629-189</a> |
            <a href="tel:0652200199">065-2200-199</a> |
            <span>Slavoljuba Mitića 2a</span>
          </div>
        </div>
        <div className="flex flex-col gap-6 items-center">
          <div className="flex items-center gap-8">
            <a
              rel="noreferrer"
              href="https://www.tiktok.com/@dejanstanisavljevic"
              target="_blank"
              className="hover:text-secondary-100 transition-colors duration-300 flex items-center gap-2"
            >
              <TikTok />
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/kamperi.prikolice.nis"
              target="_blank"
              className="hover:text-secondary-100 transition-colors duration-300 flex items-center gap-2"
            >
              <Instagram />
            </a>
            <a
              href="mailto:milliongroup2005@gmail.com"
              className="hover:text-secondary-100 transition-colors duration-300 flex items-center gap-2"
            >
              <Email />
            </a>
          </div>
          <p className="text-center">
            © Copyright 2024 Million Group | Sva prava zadržana
          </p>
          <div className="font-bold text-[250px] absolute text-white/5 top-0 leading-none -translate-x-1/2 left-0 pointer-events-none hidden lg:block">
            Million
          </div>
          <div className="font-bold text-[250px] absolute text-white/5 leading-none bottom-0 translate-x-1/2 translate-y-1/3 right-0 pointer-events-none hidden lg:block">
            group.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
