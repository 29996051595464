import React from "react";
import PocetnaHero from "./PocetnaHero";
import StaPruzamo from "./StaPruzamo/StaPruzamo";
import About from "./About";
import Navigation from "../Navigation";

const Pocetna = () => {
  return (
    <>
      <Navigation />
      <PocetnaHero />
      <About />
      <StaPruzamo />
      <div className="container-outer py-20 bg-light-100">
        <div className="container " id="lokacija">
          <iframe
            title="Mapa"
            src={
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1487.7254628869846!2d21.956618732464083!3d43.30540213954617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4755b06daa384b05%3A0xc4595cc953697b76!2z0KHQu9Cw0LLQvtGZ0YPQsdCwINCc0LjRgtC40ZvQsCAyLCDQkdGA0LfQuCDQkdGA0L7QtA!5e0!3m2!1ssr!2srs!4v1705596557082!5m2!1ssr!2srs"
            }
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="aspect-square lg:aspect-[20/9] w-full rounded-3xl"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Pocetna;
