import React from "react";

const About = () => {
  return (
    <div
      className="container-outer mb-6 pt-12 bg-light-100 text-dark-900"
      id="o-nama"
    >
      <div className="container text-center">
        <h2 className="font-bold text-4xl md:text-5xl mb-4 text-secondary-100">
          Dobrodošli
        </h2>
        <p className="max-w-[72ch] mx-auto md:text-xl mb-12">
          Mi smo strastveni tim posvećen pružanju nezaboravnih iskustava i
          putovanja kroz iznajmljivanje kampera i prikolica. Sa sedištem u Nišu,
          Million Group vam pomaže u otkrivanju destinacija nepreglednih
          pejzaža, avanturističkih putovanja i udobnosti na putu.
        </p>
        <div>
          <img src={require("../Pictures/Dobrodosli.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
