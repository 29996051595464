import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Hamburger from "./Hamburger";
import { Logo } from "./Icons/Icons";

const Navigation = () => {
  const [isShownNav, setIsShownNav] = useState(false);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="sticky top-0 z-[100] bg-secondary-100 container-outer">
      <div className="container">
        <div className="flex justify-between items-center bg-primary py-6 rounded-2xl">
          {/* <img
            src={require("./Pictures/Logo.png")}
            alt="logo"
            className="z-10 cursor-pointer h-6"
            onClick={() => {
              scrollToTop();
              setIsShownNav(false);
            }}
          /> */}
          <div
            className="cursor-pointer z-10"
            onClick={() => {
              scrollToTop();
              setIsShownNav(false);
            }}
          >
            <Logo />
          </div>
          <nav
            className={`lg:absolute text-white lg:top-[50%] lg:left-[50%] lg:-translate-x-1/2 lg:-translate-y-1/2 backdrop-blur-2xl lg:backdrop-blur-0 bg-primary lg:bg-transparent  fixed inset-0 lg:inset-auto -translate-y-full transition-[transform] duration-300 lg:transition-none ${
              isShownNav ? "translate-y-0" : ""
            }`}
          >
            <ul className="flex gap-8 lg:flex-row flex-col items-center pt-24 lg:pt-0 h-full text-2xl lg:text-base">
              <li
                onClick={() => {
                  scrollToTop();
                  setIsShownNav(false);
                }}
                className="hover:cursor-pointer"
              >
                Početna
              </li>
              <li
                onClick={() => setIsShownNav(false)}
                className="hover:cursor-pointer"
              >
                <AnchorLink href="#o-nama" offset={70}>
                  O nama
                </AnchorLink>
              </li>
              <li
                onClick={() => setIsShownNav(false)}
                className="hover:cursor-pointer"
              >
                <AnchorLink href="#sta-pruzamo" offset={90}>
                  Vozila
                </AnchorLink>
              </li>
              <li
                onClick={() => setIsShownNav(false)}
                className="hover:cursor-pointer"
              >
                <AnchorLink href="#lokacija" offset={150}>
                  Lokacija
                </AnchorLink>
              </li>
              <li
                onClick={() => setIsShownNav(false)}
                className="hover:cursor-pointer"
              >
                <AnchorLink href="#kontakt">Kontakt</AnchorLink>
              </li>
            </ul>
          </nav>
          <Hamburger isShownNav={isShownNav} setIsShownNav={setIsShownNav} />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
