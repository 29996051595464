import { Route, Routes } from "react-router-dom";
import "./App.css";
import Pocetna from "./components/Pocetna/Pocetna";
import data from "./components/Data";
import Stranica from "./components/Pocetna/StaPruzamo/Stranica";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="bg-secondary-100 text-light-100">
      <Routes>
        <Route path={"/"} element={<Pocetna />} />
        {data.map((el) => (
          <Route
            path={el.link}
            key={el.name}
            element={<Stranica data={el} key={Math.random()} />}
          />
        ))}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
