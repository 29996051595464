import React from "react";

const Hero = ({ data }) => {
  return (
    <div className="container-outer relative pb-12 md:pb-20 pt-12">
      <div className="container flex items-center justify-center">
        <div className="flex flex-col items-center lg:items-start text-center lg:text-left gap-4">
          <h2 className="font-bold text-5xl md:text-5xl">{data.name}</h2>
          <div>
            <span
              onClick={() => window.history.back()}
              className="cursor-pointer select-none hover:underline"
            >
              Početna
            </span>{" "}
            {">"} {data.name}
          </div>
          <div className="flex items-center justify-center max-w-max flex-wrap gap-x-4 gap-y-3">
            {data.ukratko.map((el) => {
              return (
                <div
                  key={el}
                  className="rounded-full text-sm px-5 py-2 bg-white/20 w-max"
                >
                  {el}
                </div>
              );
            })}
          </div>
          <div className="max-w-[70ch]">{data.tekst}</div>
        </div>
        <img
          src={require(`./Pictures/${data.heroSlika}`)}
          alt="heroslika"
          className="shrink-0 max-w-[50%] hidden lg:block"
        />
      </div>
    </div>
  );
};

export default Hero;
